// Cull objects: https://github.com/davidfig/pixi-cull

import { Container, Graphics, Sprite, Stage, Text } from "@inlet/react-pixi";
import queryString from 'query-string';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as api from "../../api/api";
import { useUser } from "../../contexts/user_provider";
import Viewport from "./Viewport";

// Load data
// import areas from "./data/areas.json";
// import markers from './data/markers.json';
// import flyzones from './data/flyzones.json';

const IS_RECORDING_COLOR = 0xff00ff;
const IS_NOT_RECORDING_COLOR = 0xff0000;
const RETURN_PATH_COLOR = 0x888888;
const FINISHED_ALPHA = 0.6;
const UNFINISHED_ALPHA = 1.0;

const droneTextStyle = {
    align: "center",
    fontWeight: "bold",
    fill: 'white',
    fontSize: 32,
    stroke: '#000000',
    strokeThickness: 2,
};

const flyzoneTextStyle = {
    align: "center",
    fontWeight: "bold",
    fontSize: 42,
    stroke: '#ffffff',
    strokeThickness: 3,
}

const markerTextStyle = {
    align: "center",
    fontWeight: "bold",
    fill: 'white',
    fontSize: 48,
    stroke: '#000000',
    strokeThickness: 3,
}

const locationTitleTextStyle = {
    align: 'center',
    fontSize: 100,
    fontWeight: 800,
    fill: '#d0d0d0',
    letterSpacing: 28
}

const Area = React.memo(function Area({ label, x, y, width, height }) {
    const [isSelected, setIsSelected] = React.useState(false);

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(1, 0x000000, 1, 0.5);
        graphic.beginFill(isSelected ? 0xff0000 : 0xffffff, 0.1);
        graphic.drawRect(x, y, width, height);
        graphic.endFill();
    };

    return (
        <Container>
            <Graphics draw={draw}
                buttonMode={true} // Finger pointer mouse button
                interactive={true}
                click={() => {
                    setIsSelected(!isSelected);
                }}
            />
            <Text
                text={label}
                anchor={0.5}
                x={x + width / 2}
                y={y + height / 2}
                style={flyzoneTextStyle}
                scale={0.10}
            />
        </Container>
    );
});

const Zone = React.memo(function Zone({ label, x, y, width, height }) {
    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(1, 0x000000, 1, 0.5);
        graphic.beginFill(0xffffff, 0.1);
        graphic.drawRect(x, y, width, height);
        graphic.endFill();
    };

    return (
        <Container>
            <Graphics draw={draw} />
            <Text
                text={label}
                anchor={0.5}
                x={x + width / 2}
                y={y + height / 2}
                style={flyzoneTextStyle}
                scale={0.15}
            />
        </Container>
    );
});

const Marker = React.memo(function Marker({ label, x, y, width, rotation, valid }) {

    function getAlpha(valid) {
        return valid === "1" ? 1.0 : 0.3;
    };

    const draw = React.useCallback(
        (graphic) => {
            const alpha = getAlpha(valid);
            const halfWidth = width * 5;
            const height = width * 10;

            graphic.clear();
            graphic.beginFill(0x0000ff, alpha);
            graphic.lineStyle(0.15, 0x0000ff, alpha);
            graphic.drawPolygon([
                x - halfWidth, y + halfWidth,
                x, y - height,
                x + halfWidth, y + halfWidth
            ]);
            graphic.endFill();

            graphic.lineStyle(0.15, 0xff0000, alpha);
            graphic.beginFill(0xff0000, alpha);
            graphic.drawCircle(x, y, 1);
            graphic.endFill();

            graphic.setTransform(x, y, 1, 1, (Number(rotation - 90) % 360) * -Math.PI / 180, 0, 0, x, y);
        },
        [x, y, width, rotation, valid]
    );

    function getLabelOffset(rotation) {
        const adjustedRotation = ((Number(rotation) + 180) % 360) - 180;
        const sign = adjustedRotation <= 0 ? -1 : 1;
        const labelOffset = width * 5;
        return sign * labelOffset;
    }

    return (
        <Container>
            <Graphics draw={draw} />
            <Text
                text={label}
                anchor={0.5}
                x={x}
                y={y + getLabelOffset(rotation)}
                style={markerTextStyle}
                scale={0.125}
                alpha={getAlpha(valid)}
            />
        </Container>
    );
});

const processCommand = (command, graphic, x, y, currentColor, currentAlpha, worldHeight, updateColorAndAlpha = true, xEnd = undefined, yEnd = undefined) => {

    if (updateColorAndAlpha) {
        currentAlpha = command.status === "succeeded" ? FINISHED_ALPHA : UNFINISHED_ALPHA;

        if (command.type === "SCHEDULE_SET_PAYLOAD_RECORDING") {
            currentColor = command.arguments.recording ? IS_RECORDING_COLOR : IS_NOT_RECORDING_COLOR;
        }
    }

    if (command.type === 'SCHEDULE_MOVE_XYZ' || command.type === 'SCHEDULE_FLY_TO_XY' || command.type === 'SCHEDULE_TAKEOFF') {
        let newX = (xEnd !== undefined) ? (xEnd * 10) : (command.arguments.x * 10);
        let newY = (yEnd !== undefined) ? (worldHeight - (yEnd * 10)) : (worldHeight - (command.arguments.y * 10));

        if (x === undefined || y === undefined) {
            graphic.drawCircle(newX, newY, /*diameter=*/0.5);
        } else {
            drawFlightLine(graphic, x, y, newX, newY, currentColor, currentAlpha);
        }
        x = newX;
        y = newY;
    }

    if (command.type === 'SCHEDULE_MOVE_XYZ') {
        if (command.arguments.action === 'START_BURST') {
            currentColor = IS_RECORDING_COLOR;
        } else if (command.arguments.action === 'STOP_BURST') {
            currentColor = IS_NOT_RECORDING_COLOR;
        }
    }

    return { x, y, currentColor, currentAlpha };
}

function isV3Task(droneCommands) {
    // return false;
    return droneCommands.some(command =>
        (command.type === "PLANNER_VERSION" || command.type === "SCHEDULE_PLANNER_VERSION") &&
        command.arguments?.version?.charAt(0) === "3"
    );
}

const ReturnPath = React.memo(function ReturnPath({ droneCommands, worldDimensions, droneFeedback }) {
    const drones = React.useMemo(() => {
        return Object.keys(droneFeedback).map(droneId => {
            const droneData = droneFeedback[droneId];
            if (!droneData) return null;

            const { x, y } = droneData.widgets.position.data;
            const newY = worldDimensions.height - (y * 10);
            return {
                droneId,
                x: x * 10,
                y: newY,
                yaw: droneData.widgets.orientation.data.yaw,
                bestReturnCommand: droneData.widgets?.bestReturnCommand?.data
            };
        }).filter(Boolean); // To filter out the nulls
    }, [droneFeedback, worldDimensions.height]);

    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            if (Array.isArray(droneCommands) && droneCommands.length > 0 && isV3Task(droneCommands) && drones) {
                const commandMap = new Map(droneCommands.map(command => [command.id, command]));

                drones.forEach((drone) => {
                    let currentColor = RETURN_PATH_COLOR;
                    let currentAlpha = UNFINISHED_ALPHA;
                    let x = drone.x;
                    let y = drone.y;

                    graphic.lineStyle(1, currentColor, currentAlpha);

                    let currentCommand = commandMap.get(drone.bestReturnCommand);
                    while (currentCommand) {
                        ({ x, y, currentColor, currentAlpha } = processCommand(currentCommand, graphic, x, y, currentColor, currentAlpha, worldDimensions.height, false));
                        const nextCommandId = currentCommand.next_command;
                        const nextCommand = nextCommandId ? commandMap.get(nextCommandId) : null;
                        if (nextCommand == null) {
                            const returnCommands = currentCommand.return_commands.map(commandId => commandMap.get(commandId));

                            if (returnCommands.length > 0) {
                                // Find the command with the shortest return duration
                                const shortestReturnCommand = returnCommands.reduce((shortest, current) => {
                                    return (current.return_duration < shortest.return_duration) ? current : shortest;
                                });
                                currentCommand = shortestReturnCommand;

                                continue;
                            }
                            else {
                                currentCommand = null;
                            }
                        }
                        else {
                            currentCommand = nextCommand;
                        }
                    }
                })
            }
        },
        [droneCommands, worldDimensions.height, drones]);

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});

const DroneCommands = React.memo(function DroneCommands({ droneCommands, worldDimensions, abortEvents }) {

    const draw = React.useCallback(
        (graphic) => {
            if (Array.isArray(droneCommands) && droneCommands.length > 0) {
                let currentColor = IS_NOT_RECORDING_COLOR;
                let currentAlpha = UNFINISHED_ALPHA;
                let x = undefined;
                let y = undefined;

                graphic.clear();
                graphic.lineStyle(1, currentColor, currentAlpha);
                if (isV3Task(droneCommands)) {
                    const commandMap = new Map(droneCommands.map(command => [command.id, command]));
                    let currentCommand = droneCommands[0];

                    while (currentCommand) {
                        if (abortEvents) {
                            const currentCommandId = currentCommand.id;
                            const matchingAbortEvent = abortEvents.find(event => event.command.id === currentCommandId);
                            if (matchingAbortEvent && matchingAbortEvent.position && matchingAbortEvent.return_command) {
                                console.log(matchingAbortEvent);
                                const { x: abortX, y: abortY } = matchingAbortEvent.position;
                                const nextCommandId = matchingAbortEvent.return_command.id;

                                ({ x, y, currentColor, currentAlpha } = processCommand(currentCommand, graphic, x, y, currentColor, currentAlpha, worldDimensions.height, true, abortX, abortY));

                                currentCommand = commandMap.get(nextCommandId);
                                continue;
                            }
                        }

                        ({ x, y, currentColor, currentAlpha } = processCommand(currentCommand, graphic, x, y, currentColor, currentAlpha, worldDimensions.height));
                        const nextCommandId = currentCommand.next_command;
                        const nextCommand = nextCommandId ? commandMap.get(nextCommandId) : null;
                        if (nextCommand == null) {
                            const returnCommands = currentCommand.return_commands.map(commandId => commandMap.get(commandId));

                            if (returnCommands.length > 0) {
                                // Find the command with the shortest return duration
                                const shortestReturnCommand = returnCommands.reduce((shortest, current) => {
                                    return (current.return_duration < shortest.return_duration) ? current : shortest;
                                });

                                currentCommand = shortestReturnCommand;
                                continue;
                            }
                            else {
                                currentCommand = null;
                            }
                        }
                        else {
                            currentCommand = nextCommand;
                        }
                    }
                } else {
                    droneCommands.forEach(command => {
                        ({ x, y, currentColor, currentAlpha } = processCommand(command, graphic, x, y, currentColor, currentAlpha, worldDimensions.height));
                    });
                }
            }
        },
        [droneCommands, worldDimensions.height, abortEvents]
    );

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});

const drawFlightLine = (graphic, startX, startY, endX, endY, color, alpha) => {
    graphic.moveTo(startX, startY);
    graphic.lineStyle(1, color, alpha);
    graphic.lineTo(endX, endY);
    graphic.drawCircle(endX, endY, /*diameter=*/0.5);

    const arrowSize = 2;
    const dx = endX - startX;
    const dy = endY - startY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const angle = Math.atan2(dy, dx);

    if (distance === 0) {
        return;
    }

    const arrowSpacing = 100; // Distance between arrows (10m)
    let arrowsToDraw = distance < arrowSpacing ? 1 : Math.floor(distance / arrowSpacing);

    for (let i = 1; i <= arrowsToDraw; i++) {
        let fraction = i / (arrowsToDraw + 1);

        // Calculate position for this arrow
        let arrowX = startX + dx * fraction;
        let arrowY = startY + dy * fraction;

        const baseCenterX = arrowX - arrowSize * Math.cos(angle);
        const baseCenterY = arrowY - arrowSize * Math.sin(angle);

        // Calculate the vertices of the triangle polygon for the arrow head
        const vertex1X = arrowX + arrowSize * Math.cos(angle);
        const vertex1Y = arrowY + arrowSize * Math.sin(angle);
        const vertex2X = baseCenterX + arrowSize * Math.cos(angle - Math.PI / 2);
        const vertex2Y = baseCenterY + arrowSize * Math.sin(angle - Math.PI / 2);
        const vertex3X = baseCenterX + arrowSize * Math.cos(angle + Math.PI / 2);
        const vertex3Y = baseCenterY + arrowSize * Math.sin(angle + Math.PI / 2);

        // Draw the triangle polygon
        graphic.beginFill(color, alpha);
        graphic.lineStyle(1, color, 0);
        graphic.moveTo(vertex1X, vertex1Y);
        graphic.lineTo(vertex2X, vertex2Y);
        graphic.lineTo(vertex3X, vertex3Y);
        graphic.lineTo(vertex1X, vertex1Y);
        graphic.endFill();
    }
};

const Measurement = React.memo(function Measurement({ x, y, worldHeight }) {
    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            graphic.lineStyle(1, 0xff0000);
            graphic.drawCircle(x * 10, worldHeight - y * 10, /*diameter=*/1);
        },
        [x, y, worldHeight]
    );

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});

const Flyzone = React.memo(function Flyzone({ x, y, width, height }) {
    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            graphic.beginFill(0x00ff00, 0.15);
            graphic.drawRect(x, y, width, height);
            graphic.endFill();
        },
        [x, y, width, height]
    );

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});

const Dock = React.memo(function Dock({ x, y, yaw }) {
    return (
        <Sprite
            image={process.env.PUBLIC_URL + "/dock.png"}
            scale={{ x: 0.015, y: 0.015 }}
            anchor={0.5}
            angle={-yaw}
            x={x}
            y={y}
        />
    );
});

function AllDrones({ droneFeedback, worldDimensions }) {
    const drones = React.useMemo(() => {
        return Object.keys(droneFeedback).map(droneId => {
            const droneData = droneFeedback[droneId];
            if (!droneData) return null;
            if (!droneData.widgets.position) return null;
            if (!droneData.widgets.orientation) return null;

            const { x, y } = droneData.widgets.position.data;
            const newY = worldDimensions.height - (y * 10);
            return {
                droneId,
                x: x * 10,
                y: newY,
                yaw: droneData.widgets.orientation.data.yaw,
                isOnline: droneData.isOnline !== undefined ? droneData.isOnline : true
            };
        }).filter(Boolean); // To filter out the nulls
    }, [droneFeedback, worldDimensions.height]);

    return (
        <>
            {drones
                .sort((a, b) => (a.isOnline === b.isOnline ? 0 : a.isOnline ? 1 : -1))
                .map(({ droneId, x, y, yaw, isOnline }) => (
                    <Drone key={droneId} droneId={droneId} x={x} y={y} yaw={yaw} isOnline={isOnline} />
                ))}
        </>
    );
}


const Drone = React.memo(function Drone({ droneId, x, y, yaw, isOnline }) {
    const droneImage = isOnline
        ? process.env.PUBLIC_URL + "/drone.png"
        : process.env.PUBLIC_URL + "/drone_offline.png";

    const offlineDroneTextStyle = {
        ...droneTextStyle,
        fill: 'gray',
    };

    const appliedTextStyle = isOnline ? droneTextStyle : offlineDroneTextStyle;

    return (
        <Container>
            <Sprite
                image={droneImage}
                scale={{ x: 0.03, y: 0.03 }}
                anchor={0.5}
                angle={-yaw}
                x={x}
                y={y}
            />
            <Text
                text={droneId}
                anchor={0.5}
                x={x}
                y={y}
                style={appliedTextStyle}
                scale={0.125}
            />
        </Container>
    );
});


const ScrollBorder = React.memo(function ScrollBorder({ x, y, width, height }) {

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(5, 0xfc03f4, 1, 0.5);
        graphic.beginFill(0xffffff, 0.15);
        graphic.drawRect(x, y, width, height);
        graphic.endFill();
    };

    return (
        <Graphics draw={draw} />
    );
});

const WorldBorder = React.memo(function WorldBorder({ x, y, width, height }) {

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(3, 0x000000, 1, 0.5);
        graphic.drawRect(x, y, width, height);
        graphic.endFill();
    };

    return (
        <Graphics draw={draw} />
    );
});

const YAxis = React.memo(function YAxis({ x }) {

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(3, 0x00ff00, 1, 0.5);
        graphic.moveTo(x, -100000)
        graphic.lineTo(x, 100000)
        graphic.endFill();
    };

    return (
        <Graphics draw={draw} />
    );
});

const XAxis = React.memo(function XAxis({ y }) {

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(3, 0xff0000, 1, 0.5);
        graphic.moveTo(-100000, y)
        graphic.lineTo(100000, y)
        graphic.endFill();
    };

    return (
        <Graphics draw={draw} />
    );
});

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const LocationTitle = React.memo(function LocationTitle({ name, x, y }) {
    return (
        <Text
            text={name}
            x={x}
            y={y}
            style={locationTitleTextStyle}
        />
    )
});

const Flight = React.memo(function Flight({ flight, worldDimensions }) {
    return (
        <>
            {
                flight?.commands && flight?.commands.length > 0 && (
                    <DroneCommands
                        droneCommands={flight.commands}
                        worldDimensions={worldDimensions}
                        abortEvents={flight.events.filter((event) => { return event.type === "FLIGHT_ABORTED"; })}
                    />
                )
            }
        </>
    );
});

export default function MapPage(props) {
    const { organizationId, locationId } = useParams();
    const { height, width } = useWindowDimensions();
    const { flightId, missionId, droneId, taskId, planner, showAreas, showFlyzones, showZones, showMarkers } = queryString.parse(props.location.search);

    const [docks, setDocks] = React.useState([]);
    const [droneCommands, setDroneCommands] = React.useState([]);
    const [droneConfigs, setDroneConfigs] = React.useState([]);
    const [droneFeedback, setDroneFeedback] = React.useState({});
    // eslint-disable-next-line
    const [_, setDroneFeedbackUnsubscribe] = React.useState([]);
    const [location, setLocation] = React.useState();
    const [areas, setAreas] = React.useState({});
    const [markers, setMarkers] = React.useState([]);
    const [flight, setFlight] = React.useState();
    const [measurements, setMeasurements] = React.useState([]);
    const [flyzones, setFlyzones] = React.useState([]);
    const [zones, setZones] = React.useState([]);

    const { getUserIsAdmin } = useUser();

    const [frameDimensions, setFrameDimensions] = React.useState({
        width: width, height: height
    });

    const [worldDimensions, setWorldDimensions] = React.useState({
        width: 1,
        height: 1,
    });

    function replaceCommandsSettingsWithArguments(commands) {
        return commands.map(command => {
            if (command.settings) {
                command.arguments = command.settings;
                delete command.settings;
            }
            return command;
        });
    }

    // document.documentElement.clientHeight,
    React.useEffect(() => {
        function handleResize() {
            setFrameDimensions({
                width: width, height: height
                // height: document.documentElement.clientHeight,
                // width: document.documentElement.clientWidth,
            });
        }

        window.addEventListener("resize", handleResize);

        return (_) => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setFrameDimensions, height, width]);

    // Subscribe to location
    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [getUserIsAdmin, organizationId, locationId]);

    // Subscribe to markers
    React.useEffect(() => {
        return api.subscribeMarkers(organizationId, locationId, setMarkers);
    }, [getUserIsAdmin, organizationId, locationId]);

    React.useEffect(() => {
        if (missionId) {
            return api.subscribeMeasurements(
                organizationId,
                locationId,
                missionId,
                setMeasurements);
        }
    }, [organizationId, locationId, missionId]);

    // Subscribe to flightId if it is specified
    React.useEffect(() => {
        if (flightId) {
            return api.subscribeFlight(organizationId, locationId, flightId, (newFlight) => {
                if (newFlight && newFlight.commands) {
                    // Map over the commands and replace settings with arguments
                    const updatedCommands = replaceCommandsSettingsWithArguments(newFlight.commands);

                    // Update the flight object with the updated commands
                    setFlight({ ...newFlight, commands: updatedCommands });
                } else {
                    setFlight(newFlight);
                }
            });
        }
    }, [organizationId, locationId, flightId]);

    // Subscribe to areas
    React.useEffect(() => {
        return api.subscribeAreas(organizationId, locationId, areas => {
            setAreas(areas);
            return areas;
        });
    }, [getUserIsAdmin, organizationId, locationId]);

    // Subscribe to flyzones
    React.useEffect(() => {
        return api.subscribeFlyzones(organizationId, locationId, setFlyzones);
    }, [getUserIsAdmin, organizationId, locationId]);

    // Subscribe to zones
    React.useEffect(() => {
        return api.subscribeZones(organizationId, locationId, setZones);
    }, [getUserIsAdmin, organizationId, locationId]);

    // React.useEffect(() => {
    //     if (organizationId && locationId) {
    //         api.getDroneConfigs(organizationId, locationId, setDrones);
    //     }
    // }, [organizationId, locationId]);

    React.useEffect(() => {
        if (organizationId && locationId) {
            return api.subscribeDocks(organizationId, locationId, setDocks);
        }
    }, [organizationId, locationId]);

    React.useEffect(() => {
        return api.subscribeDroneConfigs(organizationId, locationId, setDroneConfigs);
    }, [organizationId, locationId]);

    React.useEffect(() => {
        return api.subscribeDronesFeedback(droneConfigs, setDroneFeedbackUnsubscribe, setDroneFeedback);
    }, [droneConfigs]);

    React.useEffect(() => {
        if (taskId) {
            // If taskId is set, the requested map was one for a new mission
            return api.getTask(organizationId, locationId, taskId, (task) => {
                if (planner === 'beta') {
                    api.compileCommandsBeta(droneId, taskId)
                        .then(response => {
                            const transformedCommands = replaceCommandsSettingsWithArguments(response.data);
                            setDroneCommands(transformedCommands);
                        });
                } else {
                    api.compileCommands(droneId, task.id)
                        .then(response => {
                            const transformedCommands = replaceCommandsSettingsWithArguments(response.data);
                            setDroneCommands(transformedCommands);
                        });
                }
            });
        } else if (missionId) {
            // I don't know why this fails. Commented for now to prevent crash

            // api.compileCommandsResume(organizationId, locationId, missionId)
            //     .then(response => {
            //         setDroneCommands(response.data);
            //     });
            // No beta here
        }
    }, [organizationId, locationId, missionId, taskId, planner, droneId]);

    // Set world dimensions
    React.useEffect(() => {
        function getWorldWidth() {
            let xMax = 0;

            // Check areas
            for (const kapName in areas) {
                for (const poleName in areas[kapName]) {
                    const x = parseFloat(areas[kapName][poleName]['max']['x']);
                    if (!isNaN(x)) {
                        xMax = Math.max(xMax, x);
                    }
                }
            }

            // Check markers
            const markerList = markers['markers'] || [];
            for (const marker of markerList) {
                const markerX = parseFloat(marker.x);

                if (!isNaN(markerX)) {
                    xMax = Math.max(xMax, markerX);
                }
            }

            // Check flyzones
            for (const flyzone of flyzones) {
                const x = parseFloat(flyzone.x_max);

                if (!isNaN(x)) {
                    xMax = Math.max(xMax, x);
                }
            }

            return xMax;
        }

        function getWorldHeight() {
            let yMax = 0;

            // Check areas
            for (const kapName in areas) {
                for (const poleName in areas[kapName]) {
                    const y = parseFloat(areas[kapName][poleName]['max']['y']);
                    if (!isNaN(y)) {
                        yMax = Math.max(yMax, y);
                    }
                }
            }

            // Check markers
            const markerList = markers['markers'] || [];
            for (const marker of markerList) {
                const markerY = parseFloat(marker.y);

                if (!isNaN(markerY)) {
                    yMax = Math.max(yMax, markerY);
                }
            }

            // Check flyzones
            for (const flyzone of flyzones) {
                const y = parseFloat(flyzone.y_max);

                if (!isNaN(y)) {
                    yMax = Math.max(yMax, y);
                }
            }

            return yMax;
        }

        setWorldDimensions({ width: getWorldWidth() * 10, height: getWorldHeight() * 10 });
    }, [getUserIsAdmin, areas, flyzones, markers]);

    function getZoneMinX(zone) {
        return Math.min(...zone.contour.map(point => point.x));
    }

    function getZoneMaxX(zone) {
        return Math.max(...zone.contour.map(point => point.x));
    }

    function getZoneMinY(zone) {
        return Math.min(...zone.contour.map(point => point.y));
    }

    function getZoneMaxY(zone) {
        return Math.max(...zone.contour.map(point => point.y));
    }

    return (
        <>
            {worldDimensions.height > 1 &&
                <Stage
                    width={frameDimensions.width}
                    height={frameDimensions.height}
                    options={{ backgroundColor: 0xeeeeee, antialias: true }}
                >
                    <Viewport
                        screenWidth={frameDimensions.width}
                        screenHeight={frameDimensions.height}
                        worldHeight={worldDimensions.height + 400}
                        worldWidth={worldDimensions.width + 400}
                    >
                        <Container position={[200, 200]} >
                            <XAxis y={worldDimensions.height} />
                            <YAxis x={0} />
                            <ScrollBorder x={-200} y={-200} width={worldDimensions.width + 400} height={worldDimensions.height + 400} />
                            <WorldBorder x={0} y={0} width={worldDimensions.width} height={worldDimensions.height} />
                            <LocationTitle name={location?.name || ""} x={0} y={-125} />
                            {showFlyzones !== 'false' && (
                                flyzones.map((flyzone) => (
                                    <Flyzone
                                        key={`${flyzone.name}_${flyzone.x_min}_${flyzone.x_max}_${flyzone.y_min}_${flyzone.y_max}`}
                                        label={flyzone.name}
                                        x={flyzone.x_min * 10}
                                        y={worldDimensions.height - flyzone.y_max * 10}
                                        width={(flyzone.x_max - flyzone.x_min) * 10}
                                        height={(flyzone.y_max - flyzone.y_min) * 10}
                                    />
                                ))
                            )}
                            {showAreas !== 'false' && (
                                Object.keys(areas).map((areaKap) =>
                                    Object.keys(areas[areaKap]).map((areaPole) => (
                                        <Area
                                            key={`${areaKap}_${areaPole}`}
                                            label={`${areaKap}\n${areaPole}`}
                                            x={areas[areaKap][areaPole].min.x * 10}
                                            y={worldDimensions.height - (areas[areaKap][areaPole].max.y * 10)}
                                            width={
                                                (areas[areaKap][areaPole].max.x -
                                                    areas[areaKap][areaPole].min.x) * 10
                                            }
                                            height={
                                                (areas[areaKap][areaPole].max.y -
                                                    areas[areaKap][areaPole].min.y) * 10
                                            }
                                        />
                                    ))
                                )
                            )}
                            {showZones === 'true' && (
                                zones.map((zone, index) => (
                                    <Zone
                                        key={index}
                                        label={`${zone.name}`}
                                        x={getZoneMinX(zone) * 10}
                                        y={worldDimensions.height - (getZoneMaxY(zone) * 10)}
                                        width={(getZoneMaxX(zone) - getZoneMinX(zone)) * 10}
                                        height={(getZoneMaxY(zone) - getZoneMinY(zone)) * 10}
                                    />
                                ))
                            )}
                            {showMarkers !== 'false' && (
                                markers?.markers.map((marker, index) => (
                                    <Marker
                                        key={index}
                                        label={marker.id}
                                        x={marker.x * 10}
                                        y={worldDimensions.height - (marker.y * 10)}
                                        width={marker.width / 100}
                                        rotation={marker.yaw}
                                        valid={marker.valid} />
                                ))
                            )}
                            {docks.map((dock) => (
                                <Dock
                                    key={dock.id}
                                    x={dock.position.x * 10}
                                    y={worldDimensions.height - (dock.position.y * 10)}
                                    yaw={dock.yaw}
                                />
                            ))}
                            {measurements.map((measurement) => (
                                <Measurement
                                    key={measurement.id}
                                    x={measurement.meta.position.x}
                                    y={measurement.meta.position.y}
                                    worldHeight={worldDimensions.height}
                                />
                            ))}
                            {droneCommands && droneCommands.length > 0 && (
                                <DroneCommands
                                    droneCommands={droneCommands}
                                    worldDimensions={worldDimensions}
                                />
                            )
                            }
                            {flight &&
                                <Flight
                                    flight={flight}
                                    worldDimensions={worldDimensions}
                                />
                            }
                            {flight && flight?.commands && flight?.commands.length > 0 &&
                                <ReturnPath
                                    droneCommands={flight.commands}
                                    worldDimensions={worldDimensions}
                                    droneFeedback={droneFeedback}
                                />
                            }
                            <AllDrones
                                droneFeedback={droneFeedback}
                                worldDimensions={worldDimensions}
                            />
                        </Container>
                    </Viewport>
                </Stage>
            }
        </>
    );
}
