import React from "react";
import { Accordion, Alert, Badge, Button, ButtonGroup, Card, Dropdown, DropdownButton, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { FaChevronRight, FaCode, FaCog, FaCopy, FaDownload, FaPlay, FaPlus, FaTrash, FaUpload } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import { CorvusList } from "../../components/CorvusList";
import DevMenuButton from "../../components/DevMenuButton";
import FileExporter from "../../components/FileExporter";
import FileImporter from "../../components/FileImporter";
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import Layout, {
    TaskBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import ListItemWithToggle from "../../components/ListItemWithToggle";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";

function TaskDevMenuButton(props) {
    return (
        <DevMenuButton>
            <Dropdown.Item onClick={() => props.setViewData(!props.viewData)}>
                <FaCode className="me-2" style={{ marginTop: "-2px" }} />
                {!props.viewData && (
                    <>
                        View Data
                    </>
                )}
                {props.viewData && (
                    <>
                        Hide Data
                    </>
                )}
            </Dropdown.Item>
            <Dropdown.Divider />
            <FileExporter
                name={`task_${props.taskId}`}
                data={props.task}
            >
                <FaDownload
                    className="me-2 text-success"
                    style={{
                        marginTop: "-2px",
                    }}
                />
                Export
            </FileExporter>
            <FileImporter
                onImport={(taskJsonString) => {
                    return api.saveTask(
                        props.organizationId,
                        props.locationId,
                        props.taskId,
                        JSON.parse(taskJsonString),
                    )
                }}
            >
                <FaUpload
                    className="me-2 text-warning"
                    style={{ marginTop: "-2px" }}
                />
                Import
            </FileImporter>
            <Dropdown.Divider />
            <FirestoreDevMenuItem
                as={Dropdown.Item}
                path={`organizations/${props.organizationId}/locations/${props.locationId}/tasks/${props.taskId}`}
            />
        </DevMenuButton>
    );
}

function CommandPreview(props) {
    const { organizationId, locationId } = useParams();

    return (
        <ListGroup.Item
            key={props.commandId}
            action
            className="px-4 d-flex justify-content-between align-items-start"
            as={Link}
            to={urls.taskCommand(organizationId, locationId, props.taskId, props.commandId)}
        >
            <span>
                {/* <InputGroup>
                    <Button size="sm" variant="outline-secondary">
                        <FaChevronUp />
                    </Button>
                    <Button size="sm" variant="outline-secondary">
                        <FaEllipsisH />
                    </Button>
                    <Button size="sm" variant="outline-secondary">
                        <FaChevronDown />
                    </Button>
                </InputGroup> */}
                <span>
                    <b>{props.commandId + 1}.</b>
                    {props.command.type === "SCHEDULE_MOVE_XYZ" && (
                        <XYZPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_SCAN_ZONE" && (
                        <ScanZonePreview
                            organizationId={props.organizationId}
                            locationId={locationId}
                            command={props.command}
                        />
                    )}
                    {props.command.type === 'SCHEDULE_TAKE_PICTURE' && (
                        <TakePictureCommandPreview command={props.command} />
                    )}
                    {props.command.type === 'SCHEDULE_FLY_TO_WAYPOINT' && (
                        <FlyToWaypointCommandPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_MOVE_KAP" && (
                        <KapPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_FLY_TO_XY" && (
                        <FlyToXYCommandPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_FLY_TO_YAW" && (
                        <FlyToYawCommandPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_FLY_TO_Z" && (
                        <FlyToZCommandPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_SET_XY_SPEED" && (
                        <SetXYSpeedCommandPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_SET_PAYLOAD_RECORDING" && (
                        <SetPayloadStateCommandPreview command={props.command} />
                    )}
                    {props.command.type === "SCHEDULE_WAIT_FOR_PERIOD" && (
                        <WaitForPeriodCommandPreview command={props.command} />
                    )}
                    {props.command.type === 'SCHEDULE_SET_PICTURE_DISTANCE' && (
                        <SetPictureDistanceCommandPreview command={props.command} />
                    )}
                    {props.children}
                </span>
            </span>
            <span className="text-muted">
                <FaChevronRight />
            </span>
        </ListGroup.Item>
    );
}

function KapPreview(props) {
    return (
        <span className="m-3">
            Scan
            <code className="mx-2">
                {`${props.command.settings.startKap} ${props.command.settings.startLeg}`}
            </code>
            until
            <code className="mx-2">
                {`${props.command.settings.endKap} ${props.command.settings.endLeg}`}
            </code>
        </span>
    );
}

function XYZPreview(props) {
    return (
        <span className="m-3">
            Move to
            <code className="mx-2">
                ({' '}
                x={props.command.settings.x || 0}{' '}
                y={props.command.settings.y || 0}{' '}
                z={props.command.settings.z || 0}{' '}
                speed={props.command.settings.velocity || 0}{' '}
                yaw={props.command.settings.yaw || 0}{' '}
                delay={props.command.settings.delay || 0}{' '}
                action={props.command.settings.action || 0}{' '}
                )
            </code>{" "}
        </span>
    );
}

function FlyToXYCommandPreview(props) {
    return (
        <span className="m-3">
            Fly to
            <code className="mx-2">
                {props.command.settings.x || 0}m, {props.command.settings.y || 0}m
            </code>
            XY
        </span>
    );
}

function ScanZonePreview(props) {
    const [zones, setZones] = React.useState([]);

    React.useEffect(() => {
        api.getZones(props.organizationId, props.locationId, setZones);
    }, [props.organizationId, props.locationId]);

    function getZone(zoneId) {
        return zones?.find((zone) => zone.id === zoneId) || null;
    }

    return (
        <span className="m-3">
            Scan zone
            <code className="mx-2">
                {getZone(props.command.zone)?.name || 'None'}
            </code>
        </span>
    );
}

function TakePictureCommandPreview(props) {
    return (
        <span className="m-3">
            Take Picture
        </span>
    );
}

function FlyToWaypointCommandPreview(props) {
    return (
        <span className="m-3">
            Fly to
            <code className="mx-2">
                {props.command.settings.x || ''}m, {props.command.settings.y || ''}m, {props.command.settings.z || ''}m
            </code>
            XYZ
            <code className="mx-2">
                {props.command.settings.yaw ? props.command.settings.yaw + '°' : 'flyzone'}
            </code>
            yaw
        </span>
    );
}

function FlyToYawCommandPreview(props) {
    return (
        <span className="m-3">
            Fly to
            <code className="mx-2">
                {props.command.settings.yaw || ''}°
            </code>
            yaw
        </span>
    );
}

function FlyToZCommandPreview(props) {
    return (
        <span className="m-3">
            Fly to
            <code className="mx-2">
                {props.command.settings.z || ''}m
            </code>
            Z
        </span>
    );
}

function SetXYSpeedCommandPreview(props) {
    return (
        <span className="m-3">
            Set speed to
            <code className="mx-2">
                {props.command.settings.speed || ''}m/s
            </code>
        </span>
    );
}

function WaitForPeriodCommandPreview(props) {
    return (
        <span className="m-3">
            Wait for
            <code className="mx-2">
                {props.command.settings.period || ''}s
            </code>
            period
        </span>
    );
}

function SetPayloadStateCommandPreview(props) {
    return (
        <span className="m-3">
            {props.command.settings.recording && (
                <><span className='text-success'>Enable</span> payload recording</>
            )}
            {!props.command.settings.recording && (
                <><span className='text-danger'>Disable</span> payload recording</>
            )}
        </span>
    );
}

function SetPictureDistanceCommandPreview(props) {
    return (
        <span className="m-3">
            Set picture distance to
            <code className="mx-2">
                {props.command.settings.picture_distance || ''}m
            </code>
        </span>
    );
}

function getEnabledButNotInstalled(taskApps, installedApps, allApps) {
    // These apps have been enabled before they got uninstalled, they are
    // in the task data so we need to show them in the list of apps too.

    if (!taskApps || !installedApps || !allApps) return [];

    return allApps.filter((app) => {
        const isEnabled = taskApps.find((taskApp) => taskApp.id === app.id);
        const isInstalled = installedApps.find((installedApp) => installedApp.id === app.id);
        return isEnabled && !isInstalled;
    });
}

function taskHasApps(someTask) {
    return someTask.analyses && someTask.analyses.length !== 0;
}

function taskHasCommands(someTask) {
    return someTask.commands && someTask.commands.length !== 0;
}

export default function TaskPage(props) {
    const [viewData, setViewData] = React.useState(false);
    const [task, setTask] = React.useState(null);
    // const [notificationType, setNotificationType] = React.useState("Hovsp9cdDMmi3BfdKo9C");
    const [show, setShow] = React.useState(false);
    const { getUserIsAdmin } = useUser();
    const [installedApps, setInstalledApps] = React.useState([]);
    const [apps, setApps] = React.useState([]);
    const [location, setLocation] = React.useState();
    const { organizationId, locationId, taskId } = useParams();
    const history = useHistory();

    function handleClose() {
        setShow(false);
    }

    async function onDuplicateTask() {
        const taskCopy = { ...task };
        delete taskCopy.id;

        const newTaskId = await api.createTask(organizationId, locationId, {
            ...task,
            name: task.name + " (copy)",
        });

        history.push(urls.task(organizationId, locationId, newTaskId));
    }

    function onDeleteTask() {
        api.deleteTask(organizationId, locationId, taskId)
            .then(() => {
                history.push(urls.tasks(organizationId, locationId));
            });
    }

    async function enableApp(app) {
        let newTask = {
            ...task,
            analyses: task.analyses || [], // old // TODO : rename to apps
        };

        // old // TODO : remove
        newTask.analyses.push({
            id: app.id,
            configuration: {},
        });

        // new
        newTask.apps = newTask.analyses;

        await api.saveTask(organizationId, locationId, taskId, { ...newTask });
    }

    async function disableApp(app) {
        let newTask = {
            ...task,
            analyses: task.analyses || [], // old // TODO : rename to apps
        };

        // old
        newTask.analyses = newTask.analyses.filter((taskApp) => taskApp.id !== app.id);

        // new
        newTask.apps = newTask.analyses;

        await api.saveTask(organizationId, locationId, taskId, { ...newTask });
    }

    async function addCommand() {
        // TODO @Bas : move to XYZComand page or so...
        let newTask = { ...task };

        if (!newTask.commands) {
            newTask.commands = [];
        }
        if (getUserIsAdmin()) {
            if (newTask.commands.length === 0) {
                newTask.commands.push({
                    type: 'SCHEDULE_MOVE_XYZ',
                    settings: {
                        x: 0,
                        y: 0,
                        z: 0,
                        delay: 1.0,
                        velocity: 0,
                        yaw: 0,
                        action: "NO_ACTION",
                    },
                });
            } else {
                const previousCommand = newTask.commands[newTask.commands?.length - 1];
                newTask.commands.push(previousCommand);
            }
        } else {
            newTask.commands.push({
                type: "SCHEDULE_MOVE_KAP",
                settings: {
                    startKap: "1",
                    startLeg: "A",
                    endKap: "2",
                    endLeg: "B",
                },
            });
        }

        // setTask(newTask);
        await api.saveTask(organizationId, locationId, taskId, newTask);
    }

    React.useEffect(() => {
        if (organizationId && locationId && taskId) {
            return api.subscribeTask(organizationId, locationId, taskId, setTask);
        }
    }, [organizationId, locationId, taskId]);

    React.useEffect(() => {
        return api.getCorvusApps(setApps);
    }, []);

    React.useEffect(() => {
        api.getLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    React.useEffect(() => {
        if (location) {
            // let filteredApps = location.apps || apps;
            // const locationAppIds = filteredApps.map((a) => a.id);
            // filteredApps = apps.filter((a) => locationAppIds.includes(a.id));
            // setInstalledApps(filteredApps);
            api.getLocationApps(organizationId, locationId, setInstalledApps);

        }
    }, [organizationId, locationId, location]);

    React.useEffect(() => {
        api.getLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    function appIsEnabled(app) {
        return task.analyses?.find((ta) => ta.id === app.id) || false;
    }

    function render() {
        return (
            <Layout>
                <TaskBreadcrumbContainer />
                <Card className="mb-4">
                    <Title title={
                        <>
                            <BackButton href={urls.tasks(organizationId, locationId)} />
                            {task.name}
                        </>
                    }>
                        <div>
                            {getUserIsAdmin() && (
                                <TaskDevMenuButton
                                    className="me-2"
                                    organizationId={organizationId}
                                    locationId={locationId}
                                    taskId={taskId}
                                    viewData={viewData}
                                    task={task}
                                    setViewData={setViewData}
                                />
                            )}
                            <ButtonGroup className='d-inline'>
                                <Button
                                    variant="primary"
                                    as={Link}
                                    to={urls.taskStart(organizationId, locationId, taskId)}
                                >
                                    <FaPlay
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Start
                                </Button>
                                <DropdownButton
                                    variant="primary"
                                    align="end"
                                    as={ButtonGroup}
                                >
                                    <Dropdown.Item
                                        as={Link}
                                        to={urls.taskSettings(organizationId, locationId, taskId)}
                                    >
                                        <FaCog
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Settings
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={onDuplicateTask}
                                    >
                                        <FaCopy
                                            className="me-2 text-primary"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Duplicate
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => setShow(true)}
                                        className="text-danger"
                                    >
                                        <FaTrash
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Delete
                                    </Dropdown.Item>
                                </DropdownButton>
                            </ButtonGroup>
                        </div>
                    </Title>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Task</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure to delete task{' '}
                            <b>{task.name}</b>?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-secondary"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            {/* TODO : make common delete button */}
                            <Button
                                variant="danger"
                                onClick={() => onDeleteTask()}
                            >
                                <FaTrash
                                    className="me-2"
                                    style={{ marginTop: "-2px" }}
                                />
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {viewData && (
                        <Card.Body className="p-4 pt-0">
                            <CodeBlock name='Task' code={task} />
                        </Card.Body>
                    )}
                </Card>
                <TaskAccordion
                    task={task}
                    apps={apps}
                    locationId={locationId}
                    taskHasCommands={taskHasCommands}
                    // setNotificationType={setNotificationType}
                    addCommand={addCommand}
                    installedApps={installedApps}
                    organizationId={organizationId}
                    taskHasApps={taskHasApps}
                    appIsEnabled={appIsEnabled}
                    disableApp={disableApp}
                    enableApp={enableApp}
                    getEnabledButNotInstalled={getEnabledButNotInstalled}
                    taskId={taskId}
                />
            </Layout >
        );
    }

    return !task ? <></> : render();
}

function TaskAccordion(props) {
    return (
        <Accordion
            className="mb-4"
            defaultActiveKey={!taskHasCommands(props.task) ? 'zones' : !taskHasApps(props.task) ? 'apps' : ''}
        >
            <TaskZonesAccordionItem
                task={props.task}
                taskHasCommands={props.taskHasCommands}
                organizationId={props.organizationId}
                addCommand={props.addCommand}
                taskId={props.taskId}
                eventKey={'zones'}
            />
            <TaskAppsAccordionItem
                task={props.task}
                location={props.location}
                apps={props.apps}
                taskHasApps={taskHasApps}
                locationId={props.locationId}
                installedApps={props.installedApps}
                appIsEnabled={props.appIsEnabled}
                disableApp={props.disableApp}
                enableApp={props.enableApp}
                getEnabledButNotInstalled={props.getEnabledButNotInstalled}
                eventKey={'apps'}
            />
            {/* {getUserIsAdmin() && (
                <TaskNotificationsAccordionItem
                    task={props.task}
                    setNotificationType={props.setNotificationType}
                    eventKey="notifications"
                />
            )} */}
        </Accordion>
    );
}

function TaskZonesAccordionItem(props) {
    return (
        <Accordion.Item
            eventKey={props.eventKey}
            className="border-bottom border-top"
        >
            <Accordion.Header>
                <Badge
                    bg={taskHasCommands(props.task) ? 'primary' : 'warning'}
                    className="me-4"
                >
                    {props.task.commands?.length || 0}
                </Badge>
                Commands
            </Accordion.Header>
            <Accordion.Body className="p-0">
                {!taskHasCommands(props.task) && (
                    <Card.Body className="p-4">
                        <Alert variant="primary" className="m-0">
                            <Alert.Heading>
                                Add commands to the task
                            </Alert.Heading>
                            <p>
                                Commands tell the drone where to fly.
                            </p>
                            <Button
                                variant="primary"
                                onClick={props.addCommand}
                            >
                                <FaPlus

                                    className="me-2"
                                    style={{ marginTop: "-2px" }}
                                />
                                Add Command
                            </Button>
                        </Alert>
                    </Card.Body>
                )}
                {taskHasCommands(props.task) && (
                    <>
                        <ListGroup
                            variant="flush"
                            className="border-bottom border-top"
                        >
                            {props.task.commands.map(
                                (command, commandId) => (
                                    <CommandPreview
                                        commandId={commandId}
                                        command={command}
                                        taskId={props.taskId}
                                        organizationId={props.organizationId}
                                    />
                                )
                            )}
                        </ListGroup>
                        <Card.Body className="p-4">
                            <InputGroup>
                                <Button
                                    variant="success"
                                    onClick={props.addCommand}
                                >
                                    <FaPlus
                                        className="me-2"
                                        style={{ marginTop: "-2px" }}
                                    />
                                    Add Command
                                </Button>
                            </InputGroup>
                        </Card.Body>
                    </>
                )}
            </Accordion.Body>
        </Accordion.Item>
    );
}

function TaskAppsAccordionItem(props) {
    const { organizationId, locationId, taskId } = useParams();

    return (
        <Accordion.Item eventKey={props.eventKey}>
            <Accordion.Header>
                <Badge
                    bg={taskHasApps(props.task) ? 'primary' : 'warning'}
                    className="me-4"
                >
                    {props.task.analyses?.length || 0}
                </Badge>
                Apps
            </Accordion.Header>
            <Accordion.Body className="p-0">
                {!taskHasApps(props.task) && (
                    <Card.Body className="p-4">
                        <Alert variant="primary" className="m-0">
                            <Alert.Heading>
                                Add apps to the task
                            </Alert.Heading>
                            <p className="m-0 p-0">
                                Apps process the data collected by the drone.
                            </p>
                        </Alert>
                    </Card.Body>
                )}
                <CorvusList>
                    {props.getEnabledButNotInstalled(props.task.analyses, props.installedApps, props.apps).map((app) => (
                        <ListItemWithToggle
                            className='bg-light'
                            key={app.id}
                            item={app}
                            text={app.name}
                            enabled={props.appIsEnabled(app, props.task)}
                            onDisable={props.disableApp}
                            onEnable={props.enableApp}
                            to={urls.taskAnalyse(organizationId, locationId, taskId, app.id)}
                        />
                    ))}
                    {props.installedApps.map((app) => (
                        <ListItemWithToggle
                            className='bg-light'
                            key={app.id}
                            item={app}
                            text={app.name}
                            enabled={props.appIsEnabled(app, props.task)}
                            onDisable={props.disableApp}
                            onEnable={props.enableApp}
                            to={urls.taskAnalyse(organizationId, locationId, taskId, app.id)}
                            toggleLink={true}
                        />
                    ))}
                </CorvusList>
            </Accordion.Body>
        </Accordion.Item>
    );
}

// function TaskNotificationsAccordionItem(props) {
//     return (
//         <Accordion.Item eventKey={props.eventKey}>
//             <Accordion.Header>
//                 <FaBell
//                     className="me-4 text-primary"
//                     style={{ marginTop: "-2px" }}
//                 />
//                 Notifications
//             </Accordion.Header>
//             <Accordion.Body className="p-4">
//                 <InputGroup>
//                     <NotificationTypeSelector onSelect={props.setNotificationType} />
//                     <Button variant="success">
//                         <FaPlus className="me-2" />
//                         Add
//                     </Button>
//                 </InputGroup>
//             </Accordion.Body>
//         </Accordion.Item>
//     );
// }